import { useState } from 'react';
import {
	isResponseStatus,
	OsobyPodnikatelSouhlasDto,
	rosBusinessPersonAgreementQuery,
	usePoQuery,
} from '@gov-nx/api/portal-obcana';
import { icValidator } from '@gov-nx/utils/common';

export const useIcSearchAutocomplete = () => {
	const [text, setText] = useState<string>();

	const mainQuery = usePoQuery<OsobyPodnikatelSouhlasDto>(
		{
			queryKey: ['ic-autocomplete', text],
			queryFn: () => rosBusinessPersonAgreementQuery({ ico: text }),
			enabled: text ? text?.length > 2 && icValidator(text) : false,
			retry: 0,
			refetchOnWindowFocus: false,
		},
		{
			errorIgnoreFilter: isResponseStatus(400),
		}
	);

	return {
		isFetching: mainQuery.isFetching,
		result: mainQuery.data,
		setText,
	};
};
