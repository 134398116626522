import { JSX } from '@gov-design-system-ce/components/dist/types/components';
import { GovPrompt } from '@gov-design-system-ce/react';
import { ReactNode } from 'react';

interface PromptProps extends Omit<JSX.GovPrompt, 'role'> {
	children: ReactNode;
}

export const Prompt = (props: PromptProps) => {
	return <GovPrompt {...props}>{props.children}</GovPrompt>;
};
