import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

export function useTitle(title: string | null = null) {
	const { t } = useTranslation();

	const memoizedTitle = useMemo(() => {
		const website = t('title');
		return title ? title + ' - ' + website : website;
	}, [t, title]);

	useEffect(() => {
		const prevTitle = document.title;
		document.title = memoizedTitle;

		return () => {
			document.title = prevTitle;
		};
	}, [memoizedTitle]);
}
