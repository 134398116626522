export enum TypeOfSchool {
	SS = 'SS',
	KONZERVATOR = 'KONZERVATOR',
	VOS = 'VOS',
	VS = 'VS',
}

export const SchoolCategoryCode = {
	[TypeOfSchool.SS]: 'KO509',
	[TypeOfSchool.KONZERVATOR]: 'KO510',
	[TypeOfSchool.VOS]: 'KO511',
	[TypeOfSchool.VS]: 'KO503',
};
