import { downloadFileQuery, usePoMutation } from '@gov-nx/api/portal-obcana';
import { OnFileSaveParams } from '@gov-nx/component/common';
import { GovError } from '@gov-nx/core/app';
import {
	delay,
	getAntiVirusWarningHeader,
	getDispositionResponseHeader,
	getFileNameFromDispositionHeader,
} from '@gov-nx/utils/common';

export interface FileDownloadProps {
	onError: (id: string, e: Error) => void;
	onSuccess: (data: OnFileSaveParams) => void;
}

export const useFileDownload = (props: FileDownloadProps) => {
	const mutation = usePoMutation({
		mutationFn: downloadFileQuery,
		onError: (error, variables, context) => {
			props.onError && props.onError(variables, error as GovError);
		},
		onSuccess: async (response, id) => {
			if (response.status === 202) {
				await delay(2000);
				await mutation.mutate(id);
			} else {
				try {
					const disposition = getDispositionResponseHeader(response);
					const antivirus = getAntiVirusWarningHeader(response);
					const fileName = getFileNameFromDispositionHeader(disposition);
					const blob = response.data;
					props.onSuccess({ antivirus, fileName, blob, id });
				} catch (e) {
					props.onError(id, e as Error);
				}
			}
		},
	});
	return mutation;
};
