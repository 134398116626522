import Cookies from 'js-cookie';

export const authCookiesHelper = () => {
	const key = 'accessToken';
	return {
		getAccessToken: () => Cookies.get(key),
		removeAccessToken: () => Cookies.remove(key),
		removeAuthToken: () => Cookies.remove('authToken'),
	};
};
