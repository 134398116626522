import { useEffect, useState } from 'react';

const getWindowDimensions = () => ({
	width: window.innerWidth,
	height: window.innerHeight,
});

export const useWindowDimensions = () => {
	const [dimensions, setDimensions] = useState(getWindowDimensions());

	useEffect(() => {
		const onResize = () => {
			setDimensions(getWindowDimensions());
		};

		window.addEventListener('resize', onResize);
		return () => window.removeEventListener('resize', onResize);
	}, []);

	return dimensions;
};
