import { useCallback, useState } from 'react';

export interface ApiCallback<T extends undefined, E extends Error> {
	resolve?: (data?: T) => void;
	reject?: (e: E) => void;
}

export const useApiCallback = <T extends undefined, E extends Error>() => {
	const [callback, setCallback] = useState<ApiCallback<T, E>>();

	const onSuccess = useCallback(
		(data?: T) => {
			if (callback && callback.resolve) {
				callback.resolve(data);
			}
		},
		[callback]
	);

	const onError = useCallback(
		(e: E) => {
			if (callback && callback.reject) {
				callback.reject(e);
			}
		},
		[callback]
	);

	return { callback, setCallback, onSuccess, onError };
};
