export const yearOptions = () => {
	const currentYear = new Date().getFullYear();
	const options = [];

	for (let i = 0; i <= 10; i++) {
		const year = currentYear - i;
		const label = `${year}/${year + 1}`;
		options.push({ value: label, label });
	}

	return options;
};
