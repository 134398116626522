import React, { ReactNode } from 'react';

export interface TileListProps {
	role?: string;
	children: ReactNode;
}

export const Tiles = ({ children, role }: TileListProps) => {
	const roleValue = role ? role : 'list';

	return (
		<ul
			role={roleValue}
			className={'gov-list--plain'}>
			{children}
		</ul>
	);
};
