import { Nullable } from '@gov-nx/core/types';

export interface RegistrZPDataPreviewsParams {
	zdrojUdaju: RegistrZPZdrojUdaju;
	format: string;
}

export interface RegistrZPSubject {
	ic: string;
	name: string;
	roles: string[];
	hasSomeActiveRole: boolean;
	status?: Nullable<string>;
}

export enum RegistrZPZdrojUdaju {
	RZP_PREHLED_O_UDAJICH = 'RZP_PREHLED_O_UDAJICH',
	RZP_VYPIS_UDAJU_PS = 'RZP_VYPIS_UDAJU_PS',
}

export enum RegistrZPVztahOsoby {
	VYPIS_UDAJU_K_ME_OSOBE = 'VYPIS_UDAJU_K_ME_OSOBE',
	VYPIS_UDAJU_PRO_SUBJEKT_S_VAZBOU_K_ME_OSOBE = 'VYPIS_UDAJU_PRO_SUBJEKT_S_VAZBOU_K_ME_OSOBE',
}

export enum RegistrZPOperace {
	ULOZIT_DO_DOKUMENTU = 'ulozitdodokumentu',
	STAHNOUT = 'stahnout',
}

export enum RegistrZPDruhVypisu {
	ZADOST_O_VYPIS_UDAJU = 'ZADOST_O_VYPIS_UDAJU',
	ZADOST_O_VYPIS_UDAJU_VCETNE_HISTORIE = 'ZADOST_O_VYPIS_UDAJU_VCETNE_HISTORIE',
}
