import { useCallback, useEffect, useState } from 'react';

export const useScrollPosition = () => {
	const [scrollY, setScrollY] = useState(0);
	const [direction, setDirection] = useState<'down' | 'up'>();

	const update = useCallback(() => {
		const scrollDirection = scrollY < window.scrollY ? 'down' : 'up';
		setScrollY(window.scrollY);
		setDirection(scrollDirection);
	}, [scrollY]);

	let animationFrame: number;
	useEffect(() => {
		const onScroll = () => {
			animationFrame = requestAnimationFrame(update);
		};

		window.addEventListener('scroll', onScroll, { passive: true });

		return () => {
			window.removeEventListener('scroll', onScroll);
			cancelAnimationFrame(animationFrame);
		};
	}, [scrollY]);

	return { scrollY, direction };
};
