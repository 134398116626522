import { JSX } from '@gov-design-system-ce/components/dist/types/components';
import { GovModal } from '@gov-design-system-ce/react';
import { ReactNode } from 'react';

interface ModalProps extends Omit<JSX.GovModal, 'role'> {
	children: ReactNode;
}

export const Modal = (props: ModalProps) => {
	return <GovModal {...props}>{props.children}</GovModal>;
};
