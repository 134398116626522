import { AxiosResponse } from 'axios';
import { govApiLog } from '@gov-nx/core/app';
import { axiosInstance } from '../axios';
import { RequestData } from '../types/queryTypes';

export const sendLoggedSupportQuery = async <Params, Body>({
	body,
	params,
}: RequestData<Params, Body>): Promise<AxiosResponse<number, void>> => {
	govApiLog('/api/v1/podaniinstance/odeslat');
	return axiosInstance.post('/api/v1/podaniinstance/odeslat', body, {
		params,
		headers: {
			'Content-Type': 'multipart/form-data',
		},
	});
};

export const sendNotLoggedSupportQuery = async <Params, Body>({
	body,
	params,
}: RequestData<Params, Body>): Promise<AxiosResponse<number, void>> => {
	govApiLog('/api/public/v1/podaniinstance/odeslat');
	return axiosInstance.post('/api/public/v1/podaniinstance/odeslat', body, {
		params,
		headers: {
			'Content-Type': 'multipart/form-data',
		},
	});
};
